<template>
    <AppLayout class="dark" :title="$t('navigation.franchising')">
        <main class="isolate">
            <section class="overflow-hidden bg-white pb-32 pt-44">
                <Container size="lg" class="mb-12 text-center">
                    <h3 class="mb-5 text-xl tracking-tight text-primary-600 sm:text-3xl">
                        {{ $t('franchiseur.intro.subtitle') }}
                    </h3>
                    <h1 class="text-4xl font-light tracking-tight text-gray-900 sm:text-6xl">
                        {{ $t('franchiseur.intro.title') }}
                    </h1>
                </Container>

                <Container size="md" class="mb-12 text-center">
                    <div class="mt-6 text-lg leading-8 text-gray-600" v-html="$t('franchiseur.intro.description')" />
                </Container>

                <div class="text-center">
                    <Link :href="route('inquiry.create')" class="btn-solid-primary btn-4xl">
                        {{ $t('franchiseur.intro.cta') }}
                    </Link>
                </div>

                <Container class="mt-12 md:-mt-14">
                    <div class="grid grid-cols-2 gap-4 md:grid-cols-4">
                        <div class="col-span-2 md:order-2 md:mt-24">
                            <div class="overflow-hidden rounded-xl shadow-lg md:aspect-square">
                                <video autoplay playsinline muted loop class="h-full w-full object-cover">
                                    <source src="../../images/masonry/all-brands.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>

                        <div class="space-y-4 md:order-1 md:mt-12">
                            <div class="aspect-[3/4] overflow-hidden rounded-xl shadow-lg">
                                <img
                                    src="../../images/masonry/lepokestation.jpg"
                                    alt="Le Poke Station"
                                    class="h-full w-full object-cover"
                                />
                            </div>
                            <div class="aspect-[16/9] overflow-hidden rounded-xl shadow-lg md:aspect-[4/3]">
                                <img
                                    src="../../images/masonry/clawmee.jpg"
                                    alt="clawmee"
                                    class="h-full w-full object-cover"
                                />
                            </div>
                        </div>

                        <div class="space-y-4 md:order-3">
                            <div class="aspect-[16/9] overflow-hidden rounded-xl shadow-lg md:aspect-[4/3]">
                                <img
                                    src="../../images/masonry/sushisama.jpg"
                                    alt="Sushisama"
                                    class="h-full w-full object-cover"
                                />
                            </div>
                            <div class="aspect-[3/4] overflow-hidden rounded-xl shadow-lg">
                                <img
                                    src="../../images/masonry/ganadara.jpg"
                                    alt="Art"
                                    class="h-full w-full object-cover"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section class="bg-shark py-24 sm:py-32">
                <Container>
                    <div class="pb-24 lg:flex lg:items-center lg:justify-between">
                        <div class="mx-auto w-full max-w-xl lg:mx-0">
                            <h2 class="text-3xl font-light tracking-tight text-white sm:text-4xl">
                                {{ $t('franchiseur.portfolio.title') }}
                            </h2>
                            <div
                                class="mt-6 text-lg leading-8 text-gray-300"
                                v-html="$t('franchiseur.portfolio.description')"
                            />
                        </div>
                        <div class="mt-10 flex items-center justify-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                            <a :href="route('inquiry.create')" class="btn-solid-primary btn-4xl">
                                {{ $t('franchiseur.portfolio.cta') }}
                            </a>
                        </div>
                    </div>

                    <div
                        class="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-x-14 gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-4"
                    >
                        <a v-for="brand in brands" :key="brand.name" :href="brand.href" class="flex justify-center">
                            <img
                                class="h-12 max-w-full scale-100 object-contain transition duration-500 motion-safe:hover:scale-110 sm:motion-safe:hover:scale-125"
                                :src="brand.logo"
                                :alt="brand.name"
                            />
                        </a>
                    </div>
                </Container>
            </section>

            <section class="bg-white py-24 sm:py-32">
                <Container size="md">
                    <h1 class="text-center text-3xl font-light tracking-tight text-gray-900 sm:text-5xl">
                        {{ $t('franchiseur.steps.title') }}
                    </h1>

                    <div class="mt-20 space-y-10 divide-y divide-gray-200">
                        <div class="grid grid-cols-1 items-center gap-6 pt-10 md:grid-cols-3">
                            <h1
                                class="max-w-36 bg-cover bg-clip-text bg-center text-8xl font-black text-transparent md:order-2 md:max-w-60 md:text-[10rem]"
                                :style="`background-image: url(${step01})`"
                            >
                                01
                            </h1>
                            <div class="col-span-2">
                                <h2 class="text-2xl font-semibold tracking-tight sm:text-3xl">
                                    {{ $t('franchiseur.steps.step01.title') }}
                                </h2>
                                <div
                                    class="mt-6 text-lg leading-8 text-gray-600"
                                    v-html="$t('franchiseur.steps.step01.description')"
                                />
                            </div>
                        </div>

                        <div class="grid grid-cols-1 items-center gap-6 pt-10 md:grid-cols-3">
                            <h1
                                class="`max-w-36 bg-cover bg-clip-text bg-center text-8xl font-black text-transparent md:max-w-60 md:text-[10rem]"
                                :style="`background-image: url(${step02})`"
                            >
                                02
                            </h1>
                            <div class="col-span-2">
                                <h2 class="text-2xl font-semibold tracking-tight sm:text-3xl">
                                    {{ $t('franchiseur.steps.step02.title') }}
                                </h2>
                                <div
                                    class="mt-6 text-lg leading-8 text-gray-600"
                                    v-html="$t('franchiseur.steps.step02.description')"
                                />
                            </div>
                        </div>

                        <div class="grid grid-cols-1 items-center gap-6 pt-10 md:grid-cols-3">
                            <h1
                                class="max-w-36 bg-cover bg-clip-text bg-center text-8xl font-black text-transparent md:order-2 md:max-w-60 md:text-[10rem]"
                                :style="`background-image: url(${step03})`"
                            >
                                03
                            </h1>
                            <div class="col-span-2">
                                <h2 class="text-2xl font-semibold tracking-tight sm:text-3xl">
                                    {{ $t('franchiseur.steps.step03.title') }}
                                </h2>
                                <div
                                    class="mt-6 text-lg leading-8 text-gray-600"
                                    v-html="$t('franchiseur.steps.step03.description')"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    </AppLayout>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/App.vue';
import { Container } from '@/Components';
import franchiseurLogo from '../../images/brand/franchiseur/logo.svg';
import alkemistLogo from '../../images/brand/alkhemist/logo2.svg';
import bepLogo from '../../images/brand/bep/logo2.svg';
import clawmeeLogo from '../../images/brand/clawmee/logo.svg';
import ganadaraLogo from '../../images/brand/ganadara/logo2.svg';
import lepokestationLogo from '../../images/brand/lepokestation/logo.svg';
import onigirishopLogo from '../../images/brand/onigirishop/logo2.svg';
import sushisamaLogo from '../../images/brand/sushisama/logo2.svg';
import step01 from '../../images/steps/01.jpg';
import step02 from '../../images/steps/02.jpg';
import step03 from '../../images/steps/03.jpg';

const brands = [
    {
        name: 'Alkhemist',
        logo: alkemistLogo,
        href: route('brand.alkhemist'),
    },
    {
        name: 'BEP Cuisine Viet',
        logo: bepLogo,
        href: route('brand.bep'),
    },
    {
        name: 'ClawMee',
        logo: clawmeeLogo,
        href: route('brand.clawmee'),
    },
    {
        name: 'Ganadara',
        logo: ganadaraLogo,
        href: route('brand.ganadara'),
    },
    {
        name: 'Le PokéStation',
        logo: lepokestationLogo,
        href: route('brand.lepokestation'),
    },
    {
        name: 'Onigiri Shop',
        logo: onigirishopLogo,
        href: route('brand.onigirishop'),
    },
    {
        name: 'Sushi Sama',
        logo: sushisamaLogo,
        href: route('brand.sushisama'),
    },
    {
        name: 'Franchiseur',
        logo: franchiseurLogo,
        href: route('about-us'),
    },
];
</script>
